import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const ReduceRiskIntegratedConstructionManagement: React.FC = () => {
  return (
    <>
      <SEO
        title="Reducing risks with integrated construction management"
        description="The technology you use to manage the project and the money cannot be extraneous. To be truly effective, they must interact in the right ways"
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          How to reduce risks through integrated construction management?
        </Typography>
        <Typography variant="body1">
          Changes in construction are a fairly common procedure. But each change
          of drawing, project, contractor, material, or volume makes adjustments
          to the budget and schedules. And these changes are not always
          positive. In addition, unexpected changes in construction can increase
          the risks for the project and the achievement of the final result. And
          such nuances have changes known to you, let alone force majeure
          situations that increase risks several times.
        </Typography>
        <Typography variant="body1">
          Large projects are especially exposed to risks and more difficult to
          overcome their consequences. This is why, given the asset class,
          projects are more likely to be 80% over budget and take 20% more time
          than originally planned.
        </Typography>
        <Typography variant="body1">
          So contractors reduce risk by using an integrated cost management
          solution. What is meant by this decision? This is a specific
          technology that should be used to manage the project and money. By
          using an integrated construction management solution, you will improve
          work efficiency and team coherence. Also, you can regularly control
          costs and, in the end, get the result as close as possible to that
          which was at the design stage.
        </Typography>
        <Typography variant="body1">
          Here are 3 ways to use integrated construction management software to
          help you mitigate risk as much as possible.
        </Typography>
        <Typography variant="h2" color="blue">
          Improving team performance
        </Typography>
        <Typography variant="body1">
          The lack of communication and data exchange between the construction
          and accounting teams does not improve productivity but acts in the
          opposite direction. Such a large gap can be like a time bomb, because
          as a result of the planned budget, which is indicated in the
          accounting data, it may not be enough to carry out construction work
          and purchase quality materials.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/reduce-risk-integrated-construction-management/image-1.jpg"
            alt="Improving team performance"
            title="Improving team performance"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Our goal, as professionals, is to improve team performance work so
          that they can complete the task efficiently, within budget, and on
          time.
        </Typography>
        <Typography variant="body1">
          Any changes to the project can be made available to team members and
          available for discussion. It is improving teamwork and project
          results.
        </Typography>
        <Typography variant="body1">
          If the accounting and design staff can communicate quickly and
          efficiently on the project, with access to up-to-date information,
          then cost overruns will be eliminated. Improving team performance at
          work helps to predict, monitor, and minimize any upcoming risks.
        </Typography>
        <Typography variant="body1">
          But how to combine all the information for comfortable access? An
          integrated construction management platform helps the team improve
          performance at work because all team members can work on the same
          page. Project managers will also have access to information
          contributed by employees from different departments and thus have a
          real-time visual overview of the current situation of the project.
        </Typography>
        <Typography variant="body1">
          In essence, improving team performance strategies that work to focus
          the productivity of the field and accounting teams on their functions
          in the project. Each group has access to accurate and up-to-date data
          and promptly makes changes to the current day.
        </Typography>
        <Typography variant="h2" color="blue">
          Cost control in construction
        </Typography>
        <Typography variant="body1">
          To reduce project risks, it is very important to carry out
          construction cost control. So you can control the costs at each stage
          of the project. The best way to do this is to display costs for
          real-time analysis and use construction cost control software.
        </Typography>
        <Typography variant="body1">
          Cost control in construction can be divided into 4 segments: they are
          estimations, proposals, value schedules, and payments. By connecting 4
          elements, you can achieve the effect when all interested parties move
          in the same direction. You will see the real risks of the project at
          the moment.
        </Typography>
        <Typography variant="body1">
          This is necessary so that, for example, the budgeting department can
          see the cost limits and compare these 2 indicators. The technology
          under consideration works to automate this cycle. Investing in cost
          control software in construction allows you to focus on the job,
          rather than constantly manually sending your share of the information
          to another interested party.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/reduce-risk-integrated-construction-management/image-2.jpg"
            alt="Cost control in construction"
            title="Cost control in construction"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Make effective decisions
        </Typography>
        <Typography variant="body1">
          Effective forecasting allows stakeholders to communicate throughout
          the project.
        </Typography>
        <Typography variant="body1">
          A significant benefit of a well-integrated construction management
          technology is that it helps standardize forecasts and can create
          repeatable and verifiable processes.
        </Typography>
        <Typography variant="h2" color="blue">
          Conclusion
        </Typography>
        <Typography variant="body1">
          If your construction management software and ERP systems are out of
          sync, then your company's projects are very risky. By integrating
          systems, you create convenience and comfortable conditions for project
          participants, getting the result that you planned at the initial
          stage. After all, this is how you do not overspend and can be sure
          that the project will not become unprofitable for you.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default ReduceRiskIntegratedConstructionManagement
